













































































































import { handleError } from "@/lib/utility/handleError";
import DarkModeHighlightMixin from "@/mixins/DarkModeHighlightMixin.vue";
import PermissionMixin from "@/mixins/PermissionMixin.vue";
import ReportImageSubmission from "@/mixins/ReportImageSubmissionMixin.vue";
import { IUser } from "@/models/user.entity";
import { MrfiktivReferenceGen } from "@/services/mrfiktiv/v1/data-contracts";
import { ThgPartnerViewModelGen, ThgReferenceGen } from "@/services/thg/v1/data-contracts";
import { ActivityLogModule } from "@/store/modules/activity-log.store";
import { mixins } from "vue-class-component";
import "vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css";
import { Component, Prop, Watch } from "vue-property-decorator";
import ActivityTimeLine from "../partner/ActivityTimeLine.vue";
import ReportActivityBoxCommentComponent from "../partner/ReportActivityBoxCommentComponent.vue";
import ReportActivityBoxDocumentComponent from "../partner/ReportActivityBoxDocumentComponent.vue";
import ReportActivityBoxImageComponent from "../partner/ReportActivityBoxImageComponent.vue";
import ReportActivityBoxMailComponent from "../partner/ReportActivityBoxMailComponent.vue";
import SideCard from "../utility/SideCard.vue";
import PartnerReportTimeLineCardScrollMixin from "../partner/PartnerReportTimeLineCardScrollMixin.vue";

export type ActivityCardConfig = {
  comment: boolean;
  mail: boolean;
  document: boolean;
  image?: boolean;
  setting?: boolean;
};

export type MobileMenu = {
  title: any;
  action: () => void;
  icon: string;
  color: string;
  isEnabled: boolean;
};

/**
 * Component shows existing activities and options to add activities to timeline
 *
 * source: The source to show and create activities for
 * config: the activities that can be added with the project
 */
@Component({
  components: {
    SideCard,
    ActivityTimeLine,
    ReportActivityBoxCommentComponent,
    ReportActivityBoxImageComponent,
    ReportActivityBoxMailComponent,
    ReportActivityBoxDocumentComponent
  }
})
export default class ActivityCard extends mixins(
  DarkModeHighlightMixin,
  PermissionMixin,
  ReportImageSubmission,
  PartnerReportTimeLineCardScrollMixin
) {
  @Prop()
  partner!: ThgPartnerViewModelGen;

  @Prop()
  source!: MrfiktivReferenceGen | ThgReferenceGen;

  /**
   * User for email context
   */
  @Prop()
  user!: IUser;

  @Prop({
    default() {
      return { comment: true, mail: true, document: true };
    }
  })
  config!: ActivityCardConfig;

  /**
   * Email address used as recipient for new mail activities
   */
  @Prop()
  mail?: string;

  loading = false;

  commentSheet = false;

  get mobileMenu(): MobileMenu[] {
    const menu = [
      {
        title: this.$t("timeLine.PartnerReportDetailInfoTimeLineCard.actionCard.comment"),
        action: this.openCommentSheet,
        icon: "mdi-message",
        color: "primary",
        isEnabled: true
      }
    ];

    if (this.canCreateImage && this.config.image && this.$listeners && this.$listeners.uploadImage) {
      menu.push({
        title: this.$t("timeLine.PartnerReportDetailInfoTimeLineCard.actionCard.image"),
        action: this.emitUploadImage,
        icon: "mdi-camera",
        color: "primary",
        isEnabled: true
      });
    }

    if (this.canReadTicket && this.config.setting && this.$listeners && this.$listeners.openEdit) {
      menu.push({
        title: this.$t("designGuide.MDetailForm.props"),
        action: this.openEditSheet,
        icon: "mdi-cog",
        color: "primary",
        isEnabled: true
      });
    }

    return menu;
  }

  emitUploadImage() {
    this.$emit("uploadImage");
  }

  openCommentSheet() {
    this.commentSheet = true;
  }

  openEditSheet() {
    this.$emit("openEdit");
  }

  get activities() {
    return ActivityLogModule.paginationList;
  }

  tab = 0;

  async mounted() {
    try {
      if (!this.source.refId || !this.source.refType) {
        return;
      }
      this.loading = true;
      ActivityLogModule.paginationList.splice(0);
      await ActivityLogModule.loadDocumentsForSource({ partnerId: this.partner.id, source: this.source });
    } catch (e) {
      handleError(e);
    } finally {
      this.loading = false;
    }
  }

  @Watch("tab")
  onTabChange() {
    this.debounceScrollToBottom();
  }
}
