




















































































































import { IReport } from "@/models/report.entity";
import { ISignDocument, SignDocument } from "@/models/sign-document.entity";
import {
  MrfiktivCreateActivityLogDtoGen,
  MrfiktivDocumentViewModelGen,
  MrfiktivReferenceGen
} from "@/services/mrfiktiv/v1/data-contracts";
import { ThgReferenceGen } from "@/services/thg/v1/data-contracts";
import { ActionEnum } from "@/store/enum/authActionEnum";
import { BackendResourceEnum, ResourceEnum } from "@/store/enum/authResourceEnum";
import { ActivityLogModule, ActivityTypeEnum } from "@/store/modules/activity-log.store";
import { DocumentModule } from "@/store/modules/document.store";
import DocumentForm from "@/views/sign/DocumentForm.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import TableWrapper, { ITableWrapperHeader } from "../utility/TableWrapper.vue";
import PaginatedTable from "../utility/v2/PaginatedTable.vue";
import { mixins } from "vue-class-component";
import PartnerReportTimeLineCardScrollMixin from "./PartnerReportTimeLineCardScrollMixin.vue";

export enum ReportActivityBoxDocumentComponentSteps {
  CHOICE = "choice",
  UPLOAD_NEW = "upload_new",
  CHOOSE_EXISTING = "choose_existing",
  SUCCESS = "success"
}

@Component({
  components: { DocumentForm, TableWrapper, PaginatedTable }
})
export default class ReportActivityBoxDocumentComponent extends mixins(PartnerReportTimeLineCardScrollMixin) {
  @Prop()
  source?: MrfiktivReferenceGen | ThgReferenceGen;

  @Prop()
  report?: IReport;

  @Prop()
  partnerId!: string;

  @Prop({ default: false })
  hideButton!: boolean;

  readonly documentStore = DocumentModule;

  readonly STEPS = ReportActivityBoxDocumentComponentSteps;

  step: ReportActivityBoxDocumentComponentSteps = ReportActivityBoxDocumentComponentSteps.CHOICE;

  @Watch("step")
  emitStep() {
    this.$emit("updateStep", this.step);
  }

  isValid = false;

  isLoading = false;

  selectedItems: ISignDocument[] = [];

  get hideLeft() {
    return [ReportActivityBoxDocumentComponentSteps.CHOICE, ReportActivityBoxDocumentComponentSteps.SUCCESS].includes(
      this.step
    );
  }

  get rightDisabled() {
    return (
      this.isLoading ||
      (this.step === ReportActivityBoxDocumentComponentSteps.UPLOAD_NEW && !this.isValid) ||
      (this.step === ReportActivityBoxDocumentComponentSteps.CHOOSE_EXISTING && !this.selectedItems.length)
    );
  }

  get rightButtonText() {
    switch (this.step) {
      case ReportActivityBoxDocumentComponentSteps.CHOICE: {
        return undefined;
      }
      case ReportActivityBoxDocumentComponentSteps.CHOOSE_EXISTING: {
        return this.$t("confirm");
      }
      case ReportActivityBoxDocumentComponentSteps.UPLOAD_NEW: {
        return this.$t("save");
      }
      case ReportActivityBoxDocumentComponentSteps.SUCCESS: {
        return this.$t("timeLine.ReportActivityBoxDocumentComponent.continue");
      }
      default: {
        return undefined;
      }
    }
  }

  get onPrevious() {
    switch (this.step) {
      case ReportActivityBoxDocumentComponentSteps.CHOICE: {
        return undefined;
      }
      case ReportActivityBoxDocumentComponentSteps.CHOOSE_EXISTING: {
        return () => {
          this.step = ReportActivityBoxDocumentComponentSteps.CHOICE;
          this.scrollDown();
        };
      }
      case ReportActivityBoxDocumentComponentSteps.UPLOAD_NEW: {
        return () => {
          this.step = ReportActivityBoxDocumentComponentSteps.CHOICE;
          this.scrollDown();
        };
      }
      case ReportActivityBoxDocumentComponentSteps.SUCCESS: {
        return undefined;
      }
      default: {
        return undefined;
      }
    }
  }

  get onNext() {
    switch (this.step) {
      case ReportActivityBoxDocumentComponentSteps.CHOICE: {
        return undefined;
      }
      case ReportActivityBoxDocumentComponentSteps.CHOOSE_EXISTING: {
        return async () => {
          await this.selectItemsFromTable();
          this.scrollDown();
        };
      }
      case ReportActivityBoxDocumentComponentSteps.UPLOAD_NEW: {
        return async () => {
          await this.save();
          this.scrollDown();
        };
      }
      case ReportActivityBoxDocumentComponentSteps.SUCCESS: {
        return () => {
          this.reset();
          this.scrollDown();
        };
      }
      default: {
        return undefined;
      }
    }
  }

  get folder() {
    if (this.report) {
      return `/report/${this.report._id}`;
    }
    if (this.source) {
      return `/${this.source.refType}/${this.source.refId}`;
    }

    return "/";
  }

  get headers(): ITableWrapperHeader[] {
    return [
      { text: String(this.$t("sign.DocumentTable.type")), align: "start", value: "isTemplate", width: 150 },
      { text: String(this.$t("sign.SignDocumentForm.title")), value: "title" },
      { text: String(this.$t("sign.SignDocumentForm.name")), value: "name" },
      { text: String(this.$t("sign.SignDocumentForm.folder")), value: "folder" },
      {
        text: "",
        value: "timestamp.created",
        type: "date",
        align: "end",
        width: "100px"
      }
    ];
  }

  get choices() {
    return [
      {
        icon: "mdi-upload",
        title: this.$t("sign.ReportDocumentSignRequestStepperChoice.upload"),
        subtitle: this.$t("sign.ReportDocumentSignRequestStepperChoice.uploadSubtitle"),
        buttonText: this.$t("sign.ReportDocumentSignRequestStepperChoice.upload"),
        onClick: () => {
          this.step = ReportActivityBoxDocumentComponentSteps.UPLOAD_NEW;
          this.scrollDown();
        }
      },
      {
        icon: "mdi-file-document-multiple",
        title: this.$t("sign.ReportDocumentSignRequestStepperChoice.choose"),
        subtitle: this.$t("sign.ReportDocumentSignRequestStepperChoice.chooseSubtitle"),
        buttonText: this.$t("sign.ReportDocumentSignRequestStepperChoice.chooseButton"),
        onClick: () => {
          this.step = ReportActivityBoxDocumentComponentSteps.CHOOSE_EXISTING;
          this.scrollDown();
        }
      }
    ];
  }

  reset() {
    this.isLoading = false;
    this.step = ReportActivityBoxDocumentComponentSteps.CHOICE;
    this.selectedItems.splice(0);
  }

  scrollDown() {
    if (this.hideButton) return;

    this.debounceScrollToBottom();
  }

  async save() {
    const document = (this.$refs.documentForm as DocumentForm).createDocumentDto;
    if (!document) return;
    this.isLoading = true;

    this.$log.debug(document);

    const refs = [];
    if (this.report) {
      refs.push({
        refId: this.report._id,
        refType: ResourceEnum.REPORT
      });
    }
    if (this.source) {
      refs.push({
        refId: this.source.refId,
        refType: this.source.refType
      });
    }

    const data = {
      ...document,
      refs
    };

    const created = await new SignDocument({ ...data, partnerId: this.partnerId }).create();

    if (created.id) {
      await this.addToReport(created);
    }

    this.step = ReportActivityBoxDocumentComponentSteps.SUCCESS;
    this.isLoading = false;
  }

  async addToReport(document: MrfiktivDocumentViewModelGen) {
    try {
      const data: MrfiktivCreateActivityLogDtoGen = {
        source: {
          refType: this.source?.refType ?? BackendResourceEnum.REPORT,
          refId: this.source?.refId ?? this.report?._id ?? ""
        },
        target: [{ refType: BackendResourceEnum.DOCUMENT, refId: document.id }],
        actionType: ActionEnum.CREATE,
        activity: ActivityTypeEnum.CREATE_DOCUMENT,
        comment: document.title
      };
      const activity = await ActivityLogModule.create({
        partnerId: this.partnerId ?? this.report?.partnerId ?? "",
        data: data
      });
      ActivityLogModule.addToList(activity);
    } catch (error) {
      this.$toast.error(this.$t("components.partner.PartnerReportInitializeCard.failedActivityCreation"));
      this.$log.error(error);
    }
  }

  async selectItemFromTable(selectedItem: MrfiktivDocumentViewModelGen) {
    await this.addToReport(selectedItem);
    this.step = ReportActivityBoxDocumentComponentSteps.SUCCESS;
  }

  async selectItemsFromTable() {
    const promises = [];
    for (const selectedItem of this.selectedItems) {
      promises.push(this.addToReport(selectedItem));
    }

    await Promise.all(promises);
    this.step = ReportActivityBoxDocumentComponentSteps.SUCCESS;
  }
}
